import BaseApi from "./BaseApi";
import BenefitsMetrics from "src/models/BenefitsMetrics";
import routes from "src/routes";

export default class BenefitsMetricsApi extends BaseApi {
  get namespace(): string {
    return "benefitsMetrics";
  }

  get basePath(): string {
    return routes.api.benefitsMetrics;
  }

  get i18nPrefix(): string {
    return "benefitsMetrics";
  }

  getBenefitsMetrics = async () => {
    const { data } = await this.request<BenefitsMetrics>("GET");
    return data;
  };
}
