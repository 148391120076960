import withUser, { WithUserProps } from "../../../hoc/withUser";

import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "../../../components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import InputChoiceGroup from "../../../components/core/InputChoiceGroup";
import InputNumber from "../../../components/core/InputNumber";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import routes from "../../../routes";
import useFormState from "../../../hooks/useFormState";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../../locales/i18n";

export const fields = [
  "exemptionRequest.are_employer_contributions_required",
  "exemptionRequest.average_workforce_count",
  "exemptionRequest.should_workforce_count_include_1099_misc",
];

export const EmployerExemptionsOrganizationDetails = (props: WithUserProps) => {
  const { appLogic, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;
  const { formState, updateFields } = useFormState({});

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const handleSave = () => Promise.resolve();

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <QuestionPage
        title={t("pages.employersExemptionsOrganizationDetails.title")}
        alertBanner={
          <div>
            <Trans
              i18nKey="shared.employerExemptions.employerEIN"
              values={{
                employer_fein: user_leave_administrators[0].employer_fein,
              }}
            />
            <br />
            <br />
          </div>
        }
        onSave={handleSave}
      >
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans
                i18nKey="pages.employersExemptionsOrganizationDetails.sectionLabelHint"
                components={{}}
              />
            }
          >
            {t("pages.employersExemptionsOrganizationDetails.sectionLabel")}
          </FormLabel>

          <InputChoiceGroup
            {...getFunctionalInputProps("are_employer_contributions_required")}
            smallLabel
            choices={[
              {
                checked: formState.are_employer_contributions_required === true,
                label: t(
                  "pages.employersExemptionsOrganizationDetails.choiceYes"
                ),
                value: "true",
              },
              {
                checked:
                  formState.are_employer_contributions_required === false,
                label: t(
                  "pages.employersExemptionsOrganizationDetails.choiceNo"
                ),
                value: "false",
              },
            ]}
            type="radio"
            label={t(
              "pages.employersExemptionsOrganizationDetails.averageWorkforceSizeLastCalendarYearQuestion"
            )}
          />
          <InputChoiceGroup
            smallLabel
            {...getFunctionalInputProps(
              "should_workforce_count_include_1099_misc"
            )}
            choices={[
              {
                checked:
                  formState.should_workforce_count_include_1099_misc === true,
                label: t(
                  "pages.employersExemptionsOrganizationDetails.choiceYes"
                ),
                value: "true",
              },
              {
                checked:
                  formState.should_workforce_count_include_1099_misc === false,
                label: t(
                  "pages.employersExemptionsOrganizationDetails.choiceNo"
                ),
                value: "false",
              },
            ]}
            type="radio"
            label={t(
              "pages.employersExemptionsOrganizationDetails.halfOfWorkforcePaidThrough1099Question"
            )}
          />

          <InputNumber
            {...getFunctionalInputProps("average_workforce_count")}
            label={t(
              "pages.employersExemptionsOrganizationDetails.averageWorkforceSizeLabel"
            )}
            hint={
              <React.Fragment>
                <Trans
                  i18nKey="pages.employersExemptionsOrganizationDetails.averageWorkforceSizeHint"
                  components={{
                    "counting-total-workforce-link": (
                      <ExternalLink
                        href={routes.external.massgov.countingTotalWorkforce}
                      />
                    ),
                  }}
                />
              </React.Fragment>
            }
            smallLabel
          />
        </Fieldset>
      </QuestionPage>
    </React.Fragment>
  );
};

export default withUser(EmployerExemptionsOrganizationDetails);
