import withUser, { WithUserProps } from "../../../hoc/withUser";

import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "../../../components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import routes from "../../../routes";
import useFormState from "../../../hooks/useFormState";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../../locales/i18n";

// TODO (PFMLPB-20787): update this export with all fields being edited in this file
export const fields = ["exemptionRequest.is_self_insured_plan"];

export const EmployerExemptionsInsuranceDetails = (props: WithUserProps) => {
  const { appLogic, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;
  const { formState, updateFields } = useFormState({});
  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  // TODO (PFMLPB-20787): update to use hook
  const handleSave = () => Promise.resolve();

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <QuestionPage
        title={t("pages.employersExemptionsInsuranceDetails.title")}
        alertBanner={
          <div>
            <Trans
              i18nKey="shared.employerExemptions.employerEIN"
              values={{
                employer_fein: user_leave_administrators[0].employer_fein,
              }}
            />
            <br />
            <br />
          </div>
        }
        disableSubmit={true}
        onSave={handleSave}
      >
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans
                i18nKey="pages.employersExemptionsInsuranceDetails.sectionLabelHint"
                components={{
                  "benefit-plan-requirements-link": (
                    <ExternalLink
                      href={
                        routes.external.massgov
                          .benefitsGuide_privatePaidLeavePlanExemptions
                      }
                    />
                  ),
                }}
              />
            }
          >
            {t("pages.employersExemptionsInsuranceDetails.sectionLabel")}
          </FormLabel>
          <InputChoiceGroup
            {...getFunctionalInputProps("")}
            type="radio"
            smallLabel
            label={t(
              "pages.employersExemptionsInsuranceDetails.radioSelectHeaderPlanType"
            )}
            choices={[
              {
                value: "true",
                label: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionPurchased"
                ),
                hint: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionPurchasedHint"
                ),
              },
              {
                value: "false",
                label: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionSelfInsured"
                ),
                hint: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionSelfInsuredHint"
                ),
              },
            ]}
          />
        </Fieldset>
      </QuestionPage>
    </React.Fragment>
  );
};

// TODO (PFMLPB-20787): update this to wrap with employer exemptions hoc
export default withUser(EmployerExemptionsInsuranceDetails);
