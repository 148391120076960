import BenefitsMetrics from "../models/BenefitsMetrics";
import BenefitsMetricsApi from "src/api/BenefitsMetricsApi";
import { ErrorsLogic } from "./useErrorsLogic";
import { useState } from "react";

const useBenefitsMetricsLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
}) => {
  const benefitsMetricsApi = new BenefitsMetricsApi();

  const [benefitsMetrics, setBenefitsMetrics] = useState<BenefitsMetrics>();
  const [isLoadingBenefitsMetrics, setIsLoadingBenefitsMetrics] =
    useState<boolean>();
  const hasLoadedBenefitsMetrics = !!benefitsMetrics;

  const loadBenefitsMetrics = async () => {
    if (isLoadingBenefitsMetrics) return;
    if (hasLoadedBenefitsMetrics) return;

    setIsLoadingBenefitsMetrics(true);
    errorsLogic.clearErrors();
    try {
      const benefitsMetrics = await benefitsMetricsApi.getBenefitsMetrics();
      setBenefitsMetrics(benefitsMetrics);
    } catch (error) {
      errorsLogic.catchError(error);
    } finally {
      setIsLoadingBenefitsMetrics(false);
    }
  };

  return {
    loadBenefitsMetrics,
    benefitsMetrics,
    hasLoadedBenefitsMetrics,
    isLoadingBenefitsMetrics,
  };
};

export default useBenefitsMetricsLogic;
export type BenefitsMetricsLogic = ReturnType<typeof useBenefitsMetricsLogic>;
