import { PhoneWithExtension, PhoneWithoutExtension } from "./Phone";

import OrganizationUnit from "./OrganizationUnit";
import { ValuesOf } from "../../types/common";
import { compact } from "lodash";

/* eslint sort-keys: ["error", "asc"] */

export const MFAPreference = {
  optOut: "Opt Out",
  sms: "SMS",
} as const;

class User {
  auth_id: string;
  consented_to_data_sharing: boolean;
  consented_to_view_tax_documents: boolean | null;
  email_address: string;
  roles: UserRole[] = [];
  user_id: string;
  mfa_phone_number: PhoneWithoutExtension | null = null;
  phone_number: PhoneWithExtension | null = null;
  first_name: string | null;
  last_name: string | null;
  mfa_delivery_preference: ValuesOf<typeof MFAPreference> | null = null;
  user_leave_administrators: UserLeaveAdministrator[] = [];
  has_multiple_tax_identifiers: boolean | null = null;
  language_preference: string | undefined;

  constructor(attrs: Partial<User>) {
    Object.assign(this, attrs);
  }

  get hasEmployerRole() {
    return this.roles.some(
      (userRole) => userRole.role_description === RoleDescription.employer
    );
  }

  /**
   * Determines whether user_leave_administrators has only unverified employers
   */
  get hasOnlyUnverifiedEmployers(): boolean {
    return this.verifiedEmployers.length === 0;
  }

  /**
   * Determines whether user_leave_administrators has a verifiable employer
   */
  get hasVerifiableEmployer(): boolean {
    return this.user_leave_administrators.some((employer) =>
      this.isVerifiableEmployer(employer)
    );
  }

  /**
   * Determines whether user has MFA enabled
   */
  get hasMFAEnabled(): boolean {
    return this.mfa_delivery_preference === MFAPreference.sms;
  }

  /**
   * Determines whether a user has given consent to view tax documents
   */
  get hasConsentedToViewTaxDocuments(): boolean {
    return this.consented_to_view_tax_documents === true;
  }

  /**
   * Returns list of verified employers
   */
  get verifiedEmployers(): UserLeaveAdministrator[] {
    return this.user_leave_administrators.filter(
      (employer) => employer.verified === true
    );
  }

  /**
   * Determines whether an employer is verifiable (unverified and has verification data)
   */
  isVerifiableEmployer(employer: UserLeaveAdministrator): boolean {
    return !employer.verified && employer.has_verification_data;
  }

  /**
   * Determines whether an employer is registered in FINEOS
   */
  isEmployerIdRegisteredInFineos(employerId: string | null): boolean {
    return this.user_leave_administrators.some(
      (employer) =>
        employerId === employer.employer_id && employer.has_fineos_registration
    );
  }

  /**
   * Whether the user is associated with any organization
   */
  get hasAssociatedOrgs(): boolean {
    return this.user_leave_administrators.length > 0;
  }

  /**
   * Whether the user has verified an organization
   */
  hasVerifiedAnOrganization(): boolean {
    return this.user_leave_administrators.some((employer) => employer.verified);
  }
}

export class UserRole {
  role_description: ValuesOf<typeof RoleDescription>;
  role_id: number;

  constructor(attrs: Partial<UserRole>) {
    Object.assign(this, attrs);
  }
}

/**
 * Enums for UserRole's `role_description` field
 * @enum {string}
 */
export const RoleDescription = {
  claimant: "Claimant",
  employer: "Employer",
} as const;

export const VerificationType = {
  add: "Add",
  code: "Verification Code",
  manual: "Manual Verification",
  mtc: "MTC Number",
  withholding: "PFML Withholding",
} as const;

export class UserLeaveAdministrator {
  email_address: string;
  employer_dba: string | null;
  employer_fein: string;
  employer_id: string;
  first_name: string | null = null;
  last_name: string | null = null;
  has_fineos_registration: boolean;
  has_verification_data: boolean;
  has_verified_leave_admin: boolean;
  user_leave_administrator_id?: string;
  verified: boolean;
  organization_units: OrganizationUnit[];
  user_leave_administrator_action_id?: string;
  verification_type?: ValuesOf<typeof VerificationType> | null;
  verified_at?: string | null;
  added_at?: string | null;
  added_by?: AddedByUser | null;

  constructor(attrs: Partial<UserLeaveAdministrator>) {
    Object.assign(this, attrs);

    if (this.added_by) {
      this.added_by = new AddedByUser(this.added_by);
    }
  }

  get fullName(): string {
    return compact([this.first_name, this.last_name]).join(" ");
  }

  get id(): string | undefined {
    return (
      this.user_leave_administrator_id ||
      this.user_leave_administrator_action_id
    );
  }
}

export class AddedByUser {
  email_address: string;
  first_name?: string;
  last_name?: string;
  constructor(attrs: Partial<AddedByUser>) {
    Object.assign(this, attrs);
  }

  get displayName(): string {
    return !!this.first_name && !!this.last_name
      ? `${this.first_name} ${this.last_name}`
      : this.email_address;
  }
}

export class UserProfileHasUsableDataForApplicationResponse {
  usable_fields: string[];

  constructor(attrs: Partial<UserProfileHasUsableDataForApplicationResponse>) {
    Object.assign(this, attrs);
  }
}

export class UserProfileCheckForUpdatesRequest {
  from_application: string;

  constructor(attrs: Partial<UserProfileCheckForUpdatesRequest>) {
    Object.assign(this, attrs);
  }
}

export class UserProfileCheckForUpdatesResponse {
  profile_updates?: UserProfileCheckForUpdatesMmgFields;

  constructor(attrs: Partial<UserProfileCheckForUpdatesResponse>) {
    Object.assign(this, attrs);
  }
}

export class UserProfileCheckForUpdatesMmgFields {
  gender?: UpdatableField<string>;
  raceEthnicity?: UpdatableField<string[]>;

  constructor(attrs: Partial<UserProfileCheckForUpdatesMmgFields>) {
    Object.assign(this, attrs);
  }
}

export interface UpdatableField<T> {
  old_value: T;
  new_value: T;
}

export class UserProfileUpdate {
  from_application: string;
  profile_fields_include: Set<string>;

  constructor(attrs: Partial<UserProfileUpdate>) {
    Object.assign(this, attrs);
  }
}

export default User;
