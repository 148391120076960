/**
 * @file Configuration for building an xstate state machine for routing
 * @see https://xstate.js.org/docs/about/concepts.html#finite-state-machines
 * Each state represents a page in the portal application, keyed by the
 * page's url route. The CONTINUE transition represents the next page in the
 * the application flow.
 */
import EmployerExemptionsApplication from "../models/EmployerExemptionsApplication";
import { ExemptionRequestSteps } from "../models/EmployerExemptionsStep";
import type { GuardFn } from "./index";
import { UserLeaveAdministrator } from "../models/User";
import { fields as contactDetailsFields } from "../pages/employers/employer-exemptions/contact-details";
import { fields as insurancePlanDetailFields } from "../pages/employers/employer-exemptions/insurance-details";
import { fields as organizationDetailsFields } from "../pages/employers/employer-exemptions/organization-details";
import routes from "../routes";

export interface EmployerFlowContext {
  leaveAdmin?: UserLeaveAdministrator;
  exemptionRequest?: EmployerExemptionsApplication;
}

/**
 * Events shared by checklist and review
 */
const progressEvents = {
  CONTACT_DETAILS: routes.employers.employerExemptions.contactDetails,
  INSURANCE_DETAILS: routes.employers.employerExemptions.insuranceDetails,
  ORGANIZATION: routes.employers.employerExemptions.organizationDetails,
  DOCUMENT_UPLOAD: routes.employers.employerExemptions.documentUpload,
};
interface ConditionalEvent {
  target: string;
  cond?: string | { type: string; guardKeys: string[] };
}

export interface ExemptionsFlowState {
  meta?: {
    applicableRules?: string[];
    fields?: string[];
    step?: string;
  };
  on: { [event: string]: string | ConditionalEvent[] };
}
/**
 * @see https://xstate.js.org/docs/guides/guards.html
 */
export const guards: { [guardName: string]: GuardFn } = {
  isMissingVerificationData: ({ leaveAdmin }) => {
    return !leaveAdmin?.has_verification_data;
  },
  isVerified: ({ leaveAdmin }) => {
    return !!leaveAdmin?.verified;
  },
  hasVerificationData: ({ leaveAdmin }) => {
    return !!leaveAdmin?.has_verification_data;
  },
  noContributionsOrLeaveAdmin: ({ leaveAdmin }) => {
    return (
      !leaveAdmin?.has_verification_data &&
      !leaveAdmin?.has_verified_leave_admin
    );
  },
  noContributionsButHasLeaveAdmin: ({ leaveAdmin }) => {
    return (
      !leaveAdmin?.has_verification_data &&
      !!leaveAdmin?.has_verified_leave_admin
    );
  },
};

const employerFlow: {
  states: { [route: string]: ExemptionsFlowState };
} = {
  states: {
    [routes.employers.welcome]: {
      meta: {},
      on: {
        EDIT_USER_INFO: routes.user.contactInfo,
        VERIFY_ORGS: routes.employers.organizations,
        EXEMPTIONS: routes.employers.employerExemptions.getReady,
      },
    },
    [routes.employers.applications]: {
      meta: {},
      on: {
        VERIFY_ORG: routes.employers.organizations,
        VIEW_CLAIM: routes.employers.status,
        VIEW_LEAVE_ALLOTMENT: routes.employers.leaveAllotment,
      },
    },
    [routes.employers.dashboard]: {
      meta: {},
      on: {
        REDIRECT: routes.employers.applications,
      },
    },
    [routes.employers.addOrganization]: {
      meta: {},
      on: {
        CONTINUE: [
          {
            target: routes.employers.verifyContributions,
            cond: "hasVerificationData",
          },
          {
            target: routes.employers.verifyMTC,
            cond: "noContributionsOrLeaveAdmin",
          },
          {
            target: routes.employers.cannotVerify,
            cond: "noContributionsButHasLeaveAdmin",
          },
        ],
      },
    },
    [routes.employers.confirmation]: {
      meta: {},
      on: {},
    },
    [routes.employers.leaveAllotment]: {
      meta: {},
      on: {
        VIEW_CLAIM: routes.employers.status,
      },
    },
    [routes.employers.organizations]: {
      meta: {},
      on: {
        ADD_ORG: routes.employers.addOrganization,
        SHOW_ORG: [
          { target: routes.employers.orgDetails, cond: "isVerified" },
          {
            target: routes.employers.verifyContributions,
            cond: "hasVerificationData",
          },
          {
            target: routes.employers.verifyMTC,
            cond: "noContributionsOrLeaveAdmin",
          },
          {
            target: routes.employers.cannotVerify,
            cond: "noContributionsButHasLeaveAdmin",
          },
        ],
      },
    },
    [routes.employers.verifyMTC]: {
      meta: {},
      on: {
        CONTINUE: routes.employers.verificationSuccess,
        ERROR: routes.employers.cannotVerify,
      },
    },
    [routes.employers.review]: {
      meta: {},
      on: {
        CONTINUE: routes.employers.success,
        REDIRECT_REVIEWED_CLAIM: routes.employers.status,
      },
    },
    [routes.employers.orgDetails]: {
      meta: {},
      on: {
        ADD_LA: routes.employers.addLA,
        BACK: routes.employers.organizations,
        REMOVE_LA: routes.employers.removeLA,
      },
    },
    [routes.employers.removeLA]: {
      meta: {},
      on: {
        REDIRECT: routes.employers.orgDetails,
        REDIRECT_FROM_SELF_REMOVAL: routes.employers.organizations,
      },
    },
    [routes.employers.addLA]: {
      meta: {},
      on: {
        REDIRECT: routes.employers.orgDetails,
      },
    },
    [routes.employers.status]: {
      meta: {},
      on: {
        REDIRECT_REVIEWABLE_CLAIM: routes.employers.review,
      },
    },
    [routes.employers.success]: {
      meta: {},
      on: {
        BACK: routes.employers.applications,
      },
    },
    [routes.employers.newApplication]: {
      meta: {},
      on: {
        REDIRECT: routes.employers.status,
      },
    },
    [routes.employers.cannotVerify]: {
      meta: {},
      on: {},
    },
    [routes.employers.verificationSuccess]: {
      meta: {},
      on: {
        APPLICATIONS: routes.employers.applications,
        CONTINUE: routes.employers.organizations,
        ORG_DETAILS: routes.employers.orgDetails,
      },
    },
    [routes.employers.verifyContributions]: {
      meta: {},
      on: {
        CONTINUE: routes.employers.verificationSuccess,
      },
    },
    [routes.employers.employerExemptions.getReady]: {
      meta: {},
      on: {
        BACK: routes.employers.welcome,
        LEGAL: routes.employers.employerExemptions.legal,
      },
    },
    [routes.employers.employerExemptions.legal]: {
      meta: {},
      on: {
        BACK: routes.employers.employerExemptions.getReady,
        PROGRESS: routes.employers.employerExemptions.progress,
      },
    },
    [routes.employers.employerExemptions.contactDetails]: {
      meta: {
        fields: contactDetailsFields,
        step: ExemptionRequestSteps.contactDetails,
      },
      on: {
        CONTINUE: routes.employers.employerExemptions.progress,
      },
    },
    [routes.employers.employerExemptions.insuranceDetails]: {
      meta: {
        step: ExemptionRequestSteps.insuranceDetails,
        fields: insurancePlanDetailFields,
      },
      on: {
        CONTINUE: routes.employers.employerExemptions.progress,
      },
    },
    [routes.employers.employerExemptions.documentUpload]: {
      meta: {
        step: ExemptionRequestSteps.uploadDocuments,
      },
      on: {
        CONTINUE: routes.employers.employerExemptions.progress,
      },
    },

    [routes.employers.employerExemptions.organizationDetails]: {
      meta: {
        fields: organizationDetailsFields,
        step: ExemptionRequestSteps.organization,
      },
      on: {
        CONTINUE: routes.employers.employerExemptions.progress,
      },
    },
    [routes.employers.employerExemptions.progress]: {
      meta: {},
      on: {
        REVIEW: routes.employers.employerExemptions.review,
        ...progressEvents,
      },
    },
    [routes.employers.employerExemptions.review]: {
      meta: {},
      on: {},
    },
  },
};
export default employerFlow;
